.print-fix{
  $white : white;
  $primary: #0c549c;
  $accent: black;
  $secondary: blue;
  $green: green;
  .header-container {
    background-color: rgb(66, 65, 65);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
    color: #d5d5d5;
  }
  .header-container .header-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row:after, .row:before {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .row .col-sm-10 .form-group {
    margin: 0;
  }
  .pull-right {
    float: right!important;
  }
  #dateRange-checkBox .date-range input[type=checkbox] {
    display: none;
  }
  #dateRange-checkBox .date-range label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: #d5d5d5;
    font-size: .8em;
    margin: 0;
    line-height: 15px;
  }
  #dateRange-checkBox .date-range label:before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #d5d5d5;
    position: absolute;
    left: 7px;
    top: 2px;
    opacity: .6;
    transition: all .12s,border-color .08s;
  }
  #dateRange-checkBox .date-range input[type=checkbox]:checked+label:before {
    width: 7px;
    top: 0;
    left: 8px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  button[disabled], html input[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .6;
  }
  .palimpsest-btn-button {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    user-select: none;
    background-color: white;
    border-color: #313030;
    color: #d5d5d5;
    margin-right: 3px;
  }
  .header-container .row .costumRange-checbox input[type=checkbox] {
     display: none;
  }
  .header-container .row .costumRange-checbox input[type=checkbox]+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: #d5d5d5;
    font-weight: 500;
    font-size: .8em;
    margin: 0;
    font-weight: 700;
    line-height: 25px;
  }
  .header-container .row .costumRange-checbox input[type=checkbox]+label:before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #d5d5d5;
    position: absolute;
    left: 7px;
    top: 2px;
    opacity: .6;
    transition: all .12s,border-color .08s;
  }
  .header-container .row .costumRange-checbox input[type=checkbox]:checked+label:before {
    width: 7px;
    top: 2px;
    left: 8px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }
  @media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: left;
    }
    .col-sm-12 {
      width: 100%;
    }
    .col-sm-10 {
      width: 83.33333%;
    }
    .col-sm-7 {
      width: 58.33333%;
    }
    .col-sm-3 {
      width: 25%;
    }
    .col-sm-2 {
      width: 16.66667%;
    }
  }
  
  
  // Sports Book Page styles below
  
  .sports-book-page {
    .text-content {
      margin-top: 15px;
      .default-page-inner {
        padding: 20px;
      }
    }
    &.fixed-top {
      padding-top: 70px;
      .filters-holder {
        position: absolute;
        top: 0;
        width: calc(100% - 40px);
      }
    }
    .filters-holder	{
      display: inline-block;
      background: #dddddd;
      padding: 20px 20px 7px 20px;
      width: calc(100% - 0px);
      position: static;
      z-index: 1;
      // height: 30px;
      top: 0;
      span {
        line-height: 30px;
        font-size: 13px;
        font-weight: 500;
        color: darken($primary,10%);
        display: inline-block;
        float: left;
        .filters-icon {
          display: inline-block;
          float: right;
          margin-left: 10px;
          img {
            margin-top: 3px;
          }
        }
      }
      .search-form {
        float: left;
        width: 190px;
        height: 30px;
        margin-left: 25px;
        input[type="submit"] {
          float: left;
          width: 37px;
          height: 30px;
          background: $secondary;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border: none;
          font-size: 0;
          // background-image: url(#{$imagesPath}/search-icon.png);
          background-size: 50%;
          background-position: center left 12px;
          background-repeat: no-repeat;
          float: left;
          cursor: pointer;
        }
        input[type="text"] {
          border: 2px solid $secondary;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          line-height: 24px;
          color: darken(#887d93,10%);
          font-size: 13px;
          padding-left: 8px;
          width: 150px;
          &::-webkit-input-placeholder {
            color: #887d93;
          }
  
          &:-ms-input-placeholder {
            color: #887d93;
          }
  
          &::placeholder {
            color: #887d93;
          }
  
        }
      }
      .buttons-holder {
        float: right;
        display: inline-block;
        .single-button {
          background: lighten($primary, 15%);
          line-height: 30px;
          font-size: 14px;
          font-weight: bold;
          color: $white;
          padding: 0 7px 0 5px;
          border-radius: 3px;
          float: left;
          cursor: pointer;
          transition: .3s all;
          text-shadow: 1px 1px 1px rgba(0,0,0,.15);
          &:first-child {
            margin-right: 15px;
          }
          &:hover {
            background: $primary;
          }
          img {
            float: left;
            margin-top: 5px;
            margin-right: 10px;
            height: 20px;
            width: auto;
          }
        }
      }
    }
    .title {
      background: darken($accent, 4%);
      height: 36px;
      display: inline-block;
      width: 100%;
      .title-icon {
        padding: 0 10px;
        margin-top: 8px;
        float: left;
        cursor: pointer;
        transition: .3s all;
        &.closed {
          transform: translate(1px,-2px) rotate(-90deg);
        }
      }
      span {
        float: left;
        font-size: 14px;
        color: $white;
        line-height: 36px;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
      }
      .select-events {
        float: right;
        margin-right: 10px;
        margin-top: 5px;
        line-height: 24px;
        border: 1px solid $white;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 300;
        color: $white;
        &:hover {
          /*background: rgba($white, .06);*/
        }
        img {
          float: left;
          margin-right: 5px;
          margin-top: 4px;
        }
        .check {
          display: inline-block;
          width: 15px;
          height: 15px;
          float: left;
          margin-right: 10px;
          margin-top: 3px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 26px;
            width: 154px;
            left: -10px;
            top: -4px;
            z-index: 2;
            margin: 0;
            &:checked {
              ~ .checkmark {
                background-color: transparent;
                height: 15px;
                width: 15px;
                background-color: transparent;
                border: 1px solid $white;
                &:after {
                  left: 5px;
                  top: 2px;
                  width: 3px;
                  height: 6px;
                  border: solid $white;
                  border-width: 0 2px 2px 0;
                }
              }
              ~ .checkmark:after {
                display: block;
              }
            }
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: transparent;
            border: 1px solid $white;
            border-radius: 3px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 5px;
              top: 1px;
              width: 3px;
              height: 7px;
              border: solid $white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &:hover {
            input {
              ~ .checkmark {
                background: rgba(255,255,255,0.3);
              }
            }
          }
        }
      }
    }
    .championship-table {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #b6b3b3;
      //border-bottom: 5px solid $primary;
      background: lighten($white, 20%);
      margin-bottom: 5px;
      tfoot {
        background-color: $primary;
        td {
          padding: 5px 0;
        }
      }
      tr {
        &:nth-child(even) {
          background: #e8e8e8;
        }
        td {
          border: 1px solid #b6b3b3;
          vertical-align: middle;
          .single-tournament {
            position: relative;
            padding: 0 20px 0 5px;
            margin-top: 5px;
            .check {
              display: inline-block;
              width: 15px;
              height: 15px;
              float: left;
              margin-top: 2px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 22px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 100%;
                width: 100%;
                right: 0;
                top: 0px;
                z-index: 2;
                margin: 0;
                &:checked {
                  ~ .checkmark {
                    background-color: transparent;
                    height: 13px;
                    width: 13px;
                    background-color: transparent;
                    border: 1px solid $accent;
                    &:after {
                      left: 3px;
                      top: 1px;
                      width: 3px;
                      height: 6px;
                      border: solid $accent;
                      border-width: 0 2px 2px 0;
                    }
                  }
                  ~ .checkmark:after {
                    display: block;
                  }
                }
              }
              .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 13px;
                width: 13px;
                background-color: transparent;
                border: 1px solid lighten($accent,25%);
                border-radius: 3px;
                &:after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 5px;
                  top: 1px;
                  width: 3px;
                  height: 7px;
                  border: solid $primary;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
              &:hover {
                input {
                  ~ .checkmark {
                    background: rgba($accent, 0.05);
                  }
                }
              }
            }
            .icon {
              float: left;
              // margin-left: 5px;
              img {
                height: 14px;
                width: auto;
                margin-top: 1px;
              }
            }
            .tournament-name {
              float: left;
              font-size: 11px;
              margin-left: 5px;
              color: #1d1629;
              font-weight: normal;
              line-height: 1;
              max-width: calc(100% - 26px);
            }
            &.special {
              min-width: 180px;
              display: inline-block;
              .check {
                input {
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                }
                .checkmark {
                  top: 0;
                }
              }
              .tournament-name {
                font-weight: 500;
                line-height: 18px;
              }
            }
            &.single-print {
              .print-name {
                line-height: 17px;
                font-size: 13px;
              }
              &.special {
                text-align: right;
                .check {
                  float: none;
                }
                .print-name {
                  display: inline-block;
                  float: right;
                }
              }
            }
          }
          .tournaments-group {
            padding: 0px 0;
            display: inline-block;
            width: 100%;
            .single-tournament {
              width: calc(50% - 20px);
              padding: 1px 0px 10px 1px;
              float: left;
              margin-bottom: .5rem;
              .tournament-name {
                line-height: 17px;
              }
              &:first-child {
                &:last-child {
                  width: calc(100% - 20px);
                }
              }
            }
          }
          .print-group {
            .single-print {
              width: calc(25% - 20px);
              padding: 10px 0px 10px 20px;
              float: left;
              .print-name {
                line-height: 17px;
                font-size: 13px;
              }
              &:first-child {
                &:last-child {
                  width: calc(100% - 20px);
                }
              }
              &.special {
                text-align: right;
                .check {
                  float: none;
                }
                .print-name {
                  display: inline-block;
                }
              }
            }
            .radio-holder {
              padding: 10px 20px;
              display: inline-block;
              width: 100%;
              box-sizing: border-box;
              div,label {
                float: left;
                margin-right: 6px;
              }
              label {
                line-height: 18px;
                margin-right: 50px;
              }
              .radio {
                input {
                  right: auto;
                  left: 0;
                  width: 106px;
                }
              }
            }
          }
        }
      }
    }
    .button-holder {
      width: 100%;
      box-sizing: border-box;
      padding: 25px 15px;
      display: inline-block;
      background: $primary;
      .button-next {
        float: right;
        background-color: $green;
        border-radius: 3px;
        color: lighten($white, 20%);
        padding: 0 7px;
        font-weight: bold;
        transition: .3s all;
        line-height: 30px;
        cursor: pointer;
        i {
          color: $green;
          float: left;
          background-color: rgba(255,255,255,0.4);
          width: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 3px;
          margin-right: 10px;
          margin-top: 6px;
          transition: .3s all;
        }
        &:hover {
          background-color: darken($green,5%);
          i {
            color: darken($green,5%);
            background-color: rgba(255,255,255,0.5);
          }
        }
      }
    }
  }
}


.react-datepicker-wrapper {
  width: 50% !important;
  .react-datepicker__input-container{
    width: 100%;
  }
  input{
    width: 100%;
  }
}