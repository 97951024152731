.bg-pry{
    background: #233832;
}

.bonus{
// background: white; #e78b3d                                                                                                           
   .flex{
        justify-content: space-between;
       display: flex;
        background: #111;
        margin-top: 2rem;
   } 
    .bonus-type-row{
        display: flex;
        width: 80%;
        justify-content: space-between;
        margin: 0 auto;
        padding-top: 3rem;
        .bonus-type-card-box{
            color: white;
            .bonus-type-card{
                border: 3px solid white;
                padding: 3rem 3rem;
                border-radius: 3%;
                text-align: center;
                .bonus-card-icon{
                    border-radius: 50%;
                    border: 2px solid white;
                    width: 100px;
                    height: 100px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3rem;
                }
                .card-title{
                    font-size: 1rem;
                    padding-top: 2rem;
                }
            }
            .bonus-btn{
                background: black;
                margin-top: 1rem;
                font-weight: 600;
                color: white;
                padding: 1rem ;
            }
            .bonus-btn:hover{
                cursor: pointer;
            }
        }
    }
    .bonus-info{
        background: white;
        padding: 1rem .5rem;
        border-radius: 5px;
    }
   .bonus-title{
    background: #313031;
    color: white;
    margin-top: 0rem;
    padding: .5rem ;
   }
   .math-card{
    width: 48%;
    .math-card-header{
        width: 100%;
        h4{

            background: #313031;

            color: white;
            padding: .5rem;
        }
    }
   }
   .full{
    width: 100%;
    h4{

        background: #313031;
        color: white;
        // padding: .5rem;
    }
   }
   .table-box{
    width: 97%;
    margin: 0 auto;
    .date-box{
        background:  #e78b3d;
        color: white;
        margin-top: 2rem;
        padding: 1rem 4rem;
        border-radius: 5px;
        border: 0px;
        
      }
    .bonus-header{
        background: white;
        color: #313031;
        box-shadow:  0px 8px 16px rgba(145, 158, 171, 0.16);

        margin-top: 2rem;
        border-radius: 10px;
        border: 0px;
        border-bottom-left-radius: 0ch;
        border-bottom-left-radius: 5px;
        .span-title{
            background:  #e78b3d;
            color: white;
            padding: .1rem .4rem;
        border-radius: 5px;
            margin-right: 1rem;
        }
        .bonus-header-title{
        background:  #e78b3d;
        color:white;
        padding: 0rem 1rem;
        padding-top: 1.3rem;
        display: flex;
        justify-content: space-between;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
          
            // span:hover{
            //     background: white;
            //     color: black;
            //     padding: .1rem .4rem;
            // border-radius: 5px;
            //     margin-right: 1rem;
            // }
            span{
                cursor: pointer;
            }
            .bonus-rate{
                display: flex;
                margin: 0;
                padding: 0;
                justify-content: space-evenly;
                width: 40%;
                h3{
                    font-size: 1.5rem;
                    margin-top: -.1rem;
                    margin-bottom: 4px;
                }
            }
            .bonus-up, .bonus-rate{
                margin-bottom: .2px;
                padding-bottom: .2px;
               }
            
        }
        .bonus-header-stake{
            // background: black;
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin:0 auto;
            padding:1rem .5rem;
             h3{
                font-size: 1.1rem;
                margin-top: 1px;
            }
        }
    }
    background: white;
    table{
        border-radius: 10px;
        box-shadow:  0px 8px 16px rgba(145, 158, 171, 0.16);
        // border: 1px solid rgba(145, 158, 171, 0.24);
        background-color: #fff;
        // margin-top: 3rem  ;
         th{
            color: rgb(60, 58, 58);
            background: linear-gradient(to bottom, #f6f4f1 0, #dfdedc 11%);
        }
    }
    .total{
        font-weight: 500;
        color: black;
        background: linear-gradient(to bottom, #f6f4f1 0, #dfdedc 11%);
    }
   }
   .card-row{
       display: flex;
       justify-content: space-between;
       width: 100% !important;
   }
   .card-input{
    width: 23%;
    label{
        color: white;
        margin-bottom: .3rem;
        text-align: center;
        display: block;
    }
    input{
        width: 82%;
        color: white;
        text-align: center;
        border: 1px solid white;
        padding: .5rem;
        background: transparent;
    }
   }
   .bonus-card-symbol{
    font-size: 1.2rem;
    color: white;
    margin-left: 1rem;
    margin-top: 1.5rem;
   }
   .month-head{
    background:  #e78b3d;
    color:white;
    padding: .01px .2rem;
   }
}

.bonus-information{
    margin-top: 4rem;
    background: black;
    .bonus-information-table{
        margin-bottom: 0 ;
        border: 1px solid white;
        th{
            text-align: center;
            border-top: 1px solid white;
        }
        th, td{
            background: transparent;
            color: white;
          }
          tr{
            background: transparent;
            border-top: 1px solid white;

          }
    }
.info-note{
    color: white;
    margin-top: .2rem;
}
ul{
    color: white;
    li{
        margin: .8rem 0;
    }
}
}