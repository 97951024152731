.modal-header{
    background: #0c549c;
}
.modal{
    // margin-top: 4rem;
    z-index: 20000;
    position: relative;
}
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    // height: 80vh;
    overflow-y: auto;
}
.text-modal{
    color: #4c4c4c;
    margin: 3rem 0;
    text-align: center;
}
.withdraw-btn{
    margin-right: 2rem;
    margin-bottom: 2rem;
    input{
        padding: .5rem 1rem;
        color: white;
        border: 0px;
        margin-right: 1rem;
      }
}