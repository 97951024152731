$red: #00a651;

.btn-send {
  border: 0px;
  background: #08792a;
}

.btn-cancel {
  border: 0px;
  background: rgb(203, 15, 2);
}
.cashbook {
  color: #4c4c4c;
  margin-top: -1rem;

  .t-header {
    display: flex;
    margin-bottom: 0;
    color: white;
    background: $red;
    .ml {
     
      margin-left: -2rem;
    }

    .tab-item {
      margin-right: 2rem;
      padding: 1rem 1.5rem;
      cursor: pointer;
      border-width: 90%;
      font-size: .8rem;
      margin-right: .5rem;
    }

    .active {
      // padding: .5rem 1.5rem;
      // border: .5px solid black;
      border-bottom: .4px solid rgb(195, 193, 193);
      background: #fff;
      color: black;
    }
  }

  .tab-content {
    padding-bottom: 2rem;
    background: #fff;
    // margin-top: -.7rem;
    padding-top: 2rem;
  }

  .table-bottom {

    table,
    th,
    td {
      // border: 1px solid rgb(198, 196, 196);
      border-collapse: collapse;
    }

    margin-top: 2rem;
    background: #fff;
    padding-bottom: 4rem;
    border: 1px solid rgb(195, 193, 193);

    .tab-item {
      margin-left: -2.5rem;
      font-weight: 800;
      font-size: 1rem;
    }
  }
  .proceed {
    margin: .5rem 0;
    display: flex;
    justify-content: space-between;
  
    button {
      background: transparent;
      border: none;
      color: white;
      cursor: pointer;
    }
  
    .cancel-btn {
      background: red;
      padding: .2rem .5rem;
      cursor: pointer;
    }
  
    svg {
      font-size: 10;
      background-color: rgba(255, 255, 255, 0.5);
    }
  
    .proceed-btn {
      background: green;
      padding: .2rem .5rem;
      cursor: pointer;
    }
  }
}

.button {
  cursor: pointer;
}

.btn-create {
  float: right;
  margin-bottom: 1rem;

  button {
    border: 0px;
    background: black;
    color: white;
    padding: .5rem 1rem;
    cursor: pointer;
    margin-right: 1rem;
    // border-radius: 5px;
  }
}

.expense-form {
  margin-top: 1rem;

  .expense-input {
    margin: 1rem auto;
    width: 80%;

    select {
      width: 100%;
      padding: .5rem 1rem;
      border: 1px solid #d2d6de;
    }

    label {
      display: block;
      font-size: 1rem;
      margin-bottom: .5rem;
      color: #444242;
      text-align: left;
    }

    input {
      width: 93%;
      padding: .5rem 1rem;
      border: 1px solid #d2d6de;
    }

  }
}

.expense-btn {
  float: right;
  margin-right: 2rem;
  margin-bottom: 2rem;

  input {
    padding: .5rem 1rem;
    color: white;
    border: 0px;
    margin-right: 1rem;
  }
}

.btn-red {
  cursor: pointer;
  background:red !important;
}

.btn-green {
  background: green !important;
  cursor: pointer;
}

.new-entry {
  padding: 3rem 1rem;
}

.entry-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  label {
    color: #4c4c4c;
    font-size: .9rem;
  }

  input {
    width: 70%;

    padding: .5rem .5rem;
    border: 1px solid #d2d6de;
  }
}

.report {

  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 80%;
  margin: 2rem auto;

  .report-field {
    width: 50%;

    label {
      color: #4c4c4c;
      font-size: .9rem;
      display: block;
    }

    input {
      width: 90%;
      display: block;
      padding: .5rem .5rem;
      border: 1px solid #d2d6de;
    }
  }
}

.ft-3 {
  padding-top: 2rem;
}

.pl {
  padding-left: 1rem;
}

.funds-flex {
  .mt-2 {
    margin-top: 1.5rem !important;
  }

  .mr-2 {
    margin-right: 1rem;
  }

  .ml {
    margin-left: .5rem;
  }

  display: flex;

  .one-half {
    // float: left;
    width: 48%;
    display: block;

    .transferBtns {
      display: flex;
    }
  }

  .form-row {
    position: relative;
  }

  .form-label {
    font-weight: 700;
    margin: .5rem 0;
    font-size: 12px;
    line-height: 14px;
  }

  input:not([type=radio]):not([type=checkbox]) {
    height: 39px;
    border-radius: 3px;
    // background:red;
    // box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 10%), inset 0 0 0 1px #d7d9db;
    border: 1px solid #e4dcdc;
    width: 100%;
    padding-left: 15px;
    font-size: 13px;
  }

  textarea {
    border-radius: 3px;
    // box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 10%), inset 0 0 0 1px #d7d9db;
    border: 1px solid #e4dcdc;
    width: 100%;
    padding: 5px;
    font-size: 13px;
  }

  .btn {
    text-align: center;
    font-size: 13px;
    border-radius: 3px;
    height: 39px;
    display: block;
    cursor: pointer;
    padding: 0;
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 700;
    font-family: Arial;
    margin-top: 2rem;
    ;
    line-height: 16px;
    width: 100%;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #2c944b, #2b8f49);
  }

  .form-input--stake {
    font-size: 12px;
    position: absolute;
    top: 19%;
    transform: translateY(-50%);
    right: 15px;
    color: rgba(18, 18, 18, .7);
  }

  .quickstake {
    display: flex;
  }

  .quickstake__item {
    margin-left: .5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.2%;
    font-size: 13px;
    text-align: center;
    background-color: #f0f2f5;
    border-radius: 3px;
    height: 44px;
    margin-top: 1rem;
  }

  .dgHdrStyle TH {
    padding: 0 2px;
    text-align: center;
  }
}

.mb-4 {
  margin-bottom: 2rem;
}

.last-approved {
  .table {
    border: .7px solid #444242;

    .head {
      background: #00a651;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 1rem;
      button {
        border: 0;
        background: #2b8f49;
        color: white;
        padding: .3rem 1rem;
        cursor: pointer;
      }

      h4 {
        // margin-top: -.1rem;
        font-size: 1rem;

      }
    }

  }

  .flex {
    display: flex;
    justify-content: space-between;
    border-bottom: .1px solid #b7b2b2;

    p {
      padding: 0 .5rem;
    }
  }
}

.dashboard {
  .table {
    // border: 1px solid #2b8f49;
  }

  .head {
    h4 {
      font-size: 1rem;

    }
  }

  p {
    font-size: 1rem;
  }
}

.bottom-table {
  th {
    padding: .5rem 0;

  }
  .tr{
    border-width: 0px;
    border-style: none;
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #f4f4f4;
  }

  border-width: 0px;
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
  border-collapse: collapse;
  background: transparent;
  color: #4c4c4c;
  text-align: center;
}

.radio {
  display: flex;
  justify-content: space-evenly;
  background: white;
  padding: 1rem 0;

  .radio-group {
    display: flex;
    flex-direction: column;
    margin-top: -1.4rem;
    padding-top: 1rem;

    label {
      display: inline;

    }
  }
}

.date-group {
  background: white;

  .input-group {
    border-top: 1px solid rgb(226, 224, 224);
    display: flex;
    justify-content: center;
    padding: .2rem 0;
    align-items: center;

    label {
      margin-right: 1rem;
      width: 70px;
    }

    input {
      background: black;
      color: white;
      border: none;
      padding: .2rem;
    }

    .react-datepicker-wrapper {
      width: unset !important;

      input {
        padding: .3rem;
      }
    }
  }
}

.sales-table {
  margin-top: 0rem !important;
}

.export-btn {
  background: $red;
  margin: .5rem 1rem;
  padding: .5rem;

  button {
    border: none;
    color: white;
    cursor: pointer;
    background: transparent;
  }

  svg {
    font-size: 10;
    background-color: rgba(255, 255, 255, 0.5);
  }
}