$red:  #00a651;
.by-1{
    border-top: .1px solid rgba(235, 213, 213, 0.518);
    border-bottom: .1px solid rgba(235, 213, 213, 0.518);
}
.my-1{
    margin: 1rem 0;
}
.text-1{
    font-size: .9rem;
}
.deposit{
    button{
        padding: .5rem 2rem;
        border: 0;
        margin: 2rem 0;
        cursor: pointer;
    }
    img{
        width: 100%;
        height: 100%;
    }
    .flex{
display: flex;
align-items: center;
justify-content: center;
    }
    display: flex;
    .deposit-table{
        width: 100%;
    .deposit-table-head{
        display: flex;
        justify-content: space-between;
        width: 96%;
        background:$red;
        padding: 0 1rem;
        .first{
            width: 20%;
            text-align: center;
            border-right: 1px solid white;
        }
        }
        .deposit-table-body{
            display: flex;
            justify-content: space-between;
            width: 96%;
            padding: 0 1rem;
            border-bottom: 1px solid white;
            .first{
                width: 20%;
                text-align: center;
                border-right: 1px solid white;
                background: white;
                height: 80px;
                img{
                    width: 80%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        
        .second{
            width: 35%;
            padding: 0 1rem;
            text-align: center;
            border-right: 1px solid white;

        }
        .third, .fourth{
            width: 15%;
            text-align: center;
            border-right: 1px solid white;
        }
        .fifth{
            width: 15%;
            text-align: center;
            button{

                margin-top: 1rem;
                cursor: pointer;
                border: 0;
                padding: .5rem 1rem;
                border-radius: 5px;
            }
            button:hover{
                background: $red;
                a{
                    color: white;
            }
        }
        }
}
.deposit-step{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left{
        padding: 0 1rem;
        width: 50%;
        border: 1px solid white;
        li{
            margin: 1rem 0;
        }
    }
    .right{
        width: 50%;
        border: 1px solid white;
        padding: 1rem 1rem;
        // .form-box{
            label{
                display: block;
            }
        // }
            // width: 100%;
            input, select{
                margin: 1rem 0;
                background-color: #3d414b;
                color: white;
            }
             select{
              width: 100%; 
            }
            input{
                width: 96%;
            }
        .top{
        display: flex;
        align-items: center;
            .right-head{
                width: 200px;
                height: 100px;
                object-fit: contain;
                margin: 2rem;
                background-color: white;
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
.flex-list{
    display: flex;
    margin: 1rem 0;
    li{
        border-radius: 5px;
        text-align: center;
        background: $red;
        margin-right: .6rem;
        padding: .5rem;
        cursor: pointer;
    }
    li:hover{
        background: white;
        color: $red;
    }
}
.w-2{
    width: 23%;
}
.deposit-input{
    width: 60%;
    padding: .5rem;
    border-radius: 5px;
    border: none;
    outline: none;
}
select{
    width: 64%;
    padding: .5rem;
    border-radius: 5px;
    border: none;
    outline: none;
}
.btn-bank{
    display: flex;
    justify-content: center;
    width: 100%;
    button{
        background: $red;
        color: white;
      
    }
    button:hover{
        background:white;
        color:  $red;
    }
}
}
.jb{
    justify-content: space-between;
    padding: 0 1rem;
    .start{
        background:$red;
        color: white;
    }
    .start:hover{
        background:white;
        color: $red;
    }
    .red{
        background: red;
        color: white;
    }
    .red:hover{
        background: white;
        color: red;
    }
}
.pl-1{
    padding-left: 1rem;
}
.form-input--stake {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    color: hsla(0,0%,100%,.7);
  }
  .quickstake {
    font-size: 0;
  }
  
  .quickstake__item {
    display: inline-block;
    vertical-align: middle;
    width: 19.2%;
    font-size: 13px;
    text-align: center; 
    background-color: $red;
    border-radius: 3px;
    height: 38px;
  }
  
  div.quickstake__item, div.txt-blue, strong.txt-blue {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .quickstake__item:before {
    height: 104%;
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
  .quickstake__item+.quickstake__item {
    margin-left: 1%;
  }
