.new-table{
    td{
padding: .5rem;
    }
    tr th{
        background: #f29120;
        text-align: center;
        border-collapse: collapse;
        border:1px solid #f7f5f5;
        color: white;
    }
    tbody{
        // background: red;
        text-align: center;
        border-collapse: collapse;
        tbody tr td{
            padding: .5rem ;
            // border:1px solid #dbdada;

        }
    }
    tbody tr.odd>td,  tbody tr.odd>td {
        border-color: #dbdada;
    }
    tr:nth-child(even) {background-color: rgba(255, 0, 0, 0.082)}
    // tr:nth-child(even):hover {background-color: rgba(249, 245, 245, 0.082)}
    tr:hover{
        background-color: rgba(255, 0, 0, 0.082)
    }
}

.new-table-box{
    margin: 2rem .5rem;
    padding: .2rem .2rem;
    background: white;
    box-shadow:  0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 10px;
}